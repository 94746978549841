.maintenance-image-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.maintenance-title {
  font-size: var(--maintenace-font-size);
  color: #374151;
  font-weight: var(--heading-font-weight);
}
.maintenance-desc {
  font-size: var(--text-font-size);
  color: #7f8592;
}
.maintenance-image-container img {
  width: 100px;
}
