.custom-navbar .nav-link,
.custom-navbar .navbar-brand {
    color: white;
}

.custom-navbar {
  padding-left: "10px";
  padding-right: "10px";
  background-color: #0A5F59;
}

.truncated-name {
    display: inline-block;
    max-width: 100px;
    min-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.meeting-bi{
    width: 240px;
    margin-right: "15px";
}