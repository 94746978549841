.option-container.selected {
  background-color: #d9f6e4;
}

.option-container input[type="radio"] {
  cursor: pointer;
  appearance: none;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 4px solid #f0f0f0;
}

.option-container.selected input[type="radio"] {
  border-color: #0da14b; /* Change the border color to the desired color when selected */
  background-color: white;
  box-shadow: 0px 2px 4px #86eab0;
}
.option-container label {
  display: inline-block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

[type="checkbox"]:checked {
  background-color: #0ea14c;
  border: 2px solid #0ea14c;
}

.overflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.overflow {
  display: flex;
  /* width: 70%; */
  /* justify-content: space-between; */
}

.popover {
  max-width: 50%;
  overflow: auto;
  margin-top: 5px;
  margin-left: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.ansoffmatrix-display-container {
  flex: 1;
  margin-left: 100px;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .overflow {
    display: flex;
    flex-direction: column-reverse;
  }
  .ansoffmatrix-display-container {
    flex: 1;
    margin-left: 0px;
  }
}

.disable-xlstrategymatrix {
  background-color: white;
  pointer-events: none;
  user-select: none;
  opacity: 0.8;
}