.eco-mapping-card-title {
  width: 150px;
  font-weight: var(--heading-font-weight);
  font-size: var(--sub-heading-font-size);
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
}
.eco-mapping-card-sub-title {
  width: 80%;
  font-size: var(--sub-heading-font-size);
  padding-left: 45px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px 15px;
}
.ec-mapping-child-card {
  cursor: pointer;
  padding: 12px, 16px, 16px, 16px;
  width: 255px;
  background-color: var(--background-color);
  box-shadow: 0px 1px 4px 0px #32302c1a;
  border-radius: 5px;
  margin-left: 20px;
  margin-top: 10px;
  gap: 10px;
}

.eco_reports_list {
  list-style-type: disc;
}
/* .eco_reports_list .subsection{

} */

.eco_reports_subsection {
  margin-left: 10px;
  font-weight: var(--heading-font-weight);
  font-size: var(--sub-heading-font-size);
}
.eco_reports_subsection .name {
  background-color: var(--eco-report-sub-heading-background-color);
  width: fit-content;
  padding: 5px;
  border-radius: 2px;
  font-size: var(--sub-heading-font-size);
}
.eco_reports_child {
  margin-left: 40px;
  font-weight: 500;
  color: var(--eco-report-child-color);
  font-size: var(--sub-heading-font-size);
}
.eco_reports_subchild {
  margin-left: 70px;
  font-size: var(--text-font-size);
  color: black;
}
.eco_reports_no_data {
  margin-left: 90px;
  margin-bottom: 5px;
  color: black;
  font-size: var(--text-font-size);
}
.eco_reports_root_name {
  color: var(--eco-report-main-heading-color);
  font-size: var(--main-heading);
  font-weight: var(--heading-font-weight);
}
.eco-column-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 10px;
  height: 100vh;
  overflow-y: auto;
}

.ecmapping-ol ol,
.ecmapping-ol li {
  padding-left: 20px !important;
}
.ecmapping-ol li {
  padding-left: 10px !important;
}