.selectr {
  border-color: transparent;
  padding: 0;
  /* margin-bottom:20px !important; */
  border-radius: 10px;
}
.bidetails-form label {
  margin: 0px;
}

.bidetails-form input {
  margin: 0px;
  /* margin-bottom: 20px; */
}

.required {
  color: var(--required-color);
  padding-right: 5px;
  font-size: var(--sub-heading-font-size);
}
.bi_details-title {
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}

.bidetails-input-container {
  width: 50%;
}
.bidetails-select-container {
  width: 25%;
}
.bidetails-select-sec-container {
  width: 24.5%;
  margin-left: 10px;
}

.save-button-style-bidetails {
  width: fit-content !important;
  cursor: pointer;
  font-size: var(--sub-heading-font-size) !important;
  background-color: var(--button-primary-color) !important;
  padding: 4px 10px !important;
  margin-right: 20px !important;
}
/* Responsive for Tab */
@media (min-width: 600px) and (max-width: 1024px) {
  .bidetails-input-container {
    width: 98%;
  }
  .bidetails-select-container {
    width: 50%;
  }
  .bidetails-select-sec-container {
    width: 46.5%;
    margin-left: 10px;
  }
}
