.bordered-table{
    border-collapse: collapse;
}

/* .bordered-table th{
    border: 1px solid #ccc;
} */

.table-container {
    display: grid;
    grid-template-columns: auto 1fr; /* Adjust the column widths as needed */
    gap: 4px;
  }
  
  .vertical-column {
    display: grid;
    grid-auto-rows: min-content;
    gap: 4px;
  }
  
  .vertical-cell {
    padding: 8px;
    background-color: lightgray;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    gap: 4px;
  }
  
  .header-cell {
    padding: 8px;
    background-color: gray;
    color: white;
  }
  
  .grid-cell {
    padding: 8px;
    background-color: white;
  }
  .option-container.selected{
    background-color: #D9F6E4;
  }
  
  .option-container input[type="radio"] {
  
  cursor:pointer;
  appearance: none;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 4px solid #f0f0f0;
  }
  
  .option-container.selected input[type="radio"] {
    border-color: #0DA14B; /* Change the border color to the desired color when selected */
    background-color: white;
    box-shadow: 0px 2px 4px #86EAB0;
  }
  .option-container label {
    display: inline-block;
    margin: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }