.collapsible-section {
  margin-bottom: 20px;
}

.collapsible-section:first-child {
  margin-top: 60px;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ecf4f3;
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
}

.arrow {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(90deg);
}

.section-content {
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
  display: none;
  background-color: #ffffff;
  padding: 10px;
}

.collapsible-section.open .section-content {
  display: block;
}

/* .template-card{
    margin-right: 10px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-width: thin;
    padding: 0px 10px 10px 0;
    margin-bottom: 10px;
} */

.template-card {
  margin-bottom: 10px;
}

.selected-template {
  background-color: #ecf4f3;
  padding: 10px 5px;
}

.no-templates-message {
  text-align: center;
  font-size: 18px;
  padding: 20px;
}

.checkTemplate {
  position: relative;
  display: flex;
  justify-content: end;
}

.card-content {
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  text-align: start;
}

.selected-template:has(.card-content) .card-content {
  padding-top: 0px;
}

.card-image {
  /* width: 40px; */
  /* height: 4px;
    margin-right: 15px; */
  width: 20px;
}

.card-label {
  color: black;
  cursor: pointer;
  /* width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
  padding-right: 5px;
  padding-left: 5px;
  font-size: var(--text-font-size);
}

.templates-rhpo-data {
  font-size: var(--sub-heading-font-size);
}
