.form-control {
  margin-bottom: 10px;
}
.metrics-input {
  height: 40px;
  width: 400px;
  border-radius: 10px;
  padding-left: 20px;
}
.selectmt {
  width: 400px;
  margin-left: 10px;
}

.selectmtty {
  width: 250px;
  padding-left: 2px;
}
.alert-danger {
  padding: 5px;
}

.metrics-outer-container {
  width: 26%;
  height: 600px;
}
.metrics-outer-container h5 {
  text-align: center;
}
.metrics-inner-container {
  overflow-y: auto;
  width: 100%;
  height: 550px;
  border: 1px solid gray;
  border-radius: 10px;
}
.metrics-create-button {
  font-size: var(--sub-heading-font-size) !important;
  background-color: white !important;
  color: black !important;
  text-align: center;
  border: 1px ridge !important;
  padding: 6px 10px !important;
}
