.fun-question {
  /* background: linear-gradient(0deg, #eeeeee, #eeeeee),
      linear-gradient(0deg, #f8f8f8, #f8f8f8); */
  padding: 5px 20px;
  margin-left: 30px;
  margin-right: 20px;
  width: fit-content;
}

.user-input {
  padding: 5px 20px;
  margin-left: 20px;
}

.option-input {
  width: 400px;
}

.fun-subsection {
  box-shadow: 0px 1px 4px 0px #32302c1a;
  /* width: fit-content; */
  width: 97%;
  padding: 5px 10px;
  margin-left: 25px;
}
