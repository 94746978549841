.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    transition: 0.3s;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ecf4f3;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #0A5F59;
    color:white;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }

  .App {
    font-family: "Source Sans Pro";
    text-align: center;
  }
  
  .button-wrapper button {
    z-index: 1;
    height: 40px;
    max-width: 200px;
    margin: 10px;
    padding: 5px;
  }
  
  .excalidraw .App-menu_top .buttonList {
    display: flex;
  }
  
  .excalidraw-wrapper {
    height: 800px;
    margin: 50px;
  }
  
  :root[dir="ltr"] .excalidraw .layer-ui__wrapper .zen-mode-transition.App-menu_bottom--transition-left {
    transform: none
  }
  
  .excalidraw .panelColumn {
    text-align: left;
  }
  
  .export-wrapper {
    display: flex;
    flex-direction: column;
    margin: 50px;
  
    &__checkbox {
      display: flex;
    }
  }
  .layer-ui__wrapper__top-right.zen-mode-transition{
    display:none !important
  }

  .dropdown-menu-button.zen-mode-transition{
    display:none !important
  }
  .HelpDialog__header{
    display:none !important
  }

  /* .excalidraw .popover{
    position: initial !important;
  } */

  .notes-rs-items::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    height: 0px;
    margin-left:5px;
  }
  
  /* Track */
  .notes-rs-items::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .notes-rs-items::-webkit-scrollbar-thumb {
    background: #888;
    border-radius:10px;
  }
  
  /* Handle on hover */
  .notes-rs-items::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius:10px;

  }

  div .Stack.Stack_horizontal label:nth-of-type(11) {
    display:none !important
  }

  .excalidraw .ToolIcon .ToolIcon__keybinding{
    display: none;
  }

  .excalidraw .App-toolbar__divider{
    display:none
  }

  .popover .context-menu  li:nth-of-type(1){
    display:none
  }
  .popover .context-menu  li:nth-of-type(2){
    display:none
  }
  .popover .context-menu  li:nth-of-type(5){
    display:none
  }
  .popover .context-menu  hr:nth-of-type(2){
    display:none
  }

  .popover .context-menu  hr:nth-of-type(1){
    display:none
  }
  .tox .tox-editor-header{
    z-index: 0;
  }