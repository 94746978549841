.bordered-ca-table {
  background-color: white;
}

.bordered-ca-table tr:last-child td {
  padding-bottom: 10px;
}
.bordered-ca-table td:first-child {
  white-space: nowrap;
  position: sticky;
  left: 0;
  background-color: white;
}
.bordered-ca-table thead {
  white-space: nowrap;
  position: sticky;
  /* border-top: 1px solid #ccc;
    border-bottom: thin ridge; */
  /* box-shadow: 0px 0px 1px 0px rgba(0.2, 0.2, 0.2, 0.2); */
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.3),
    inset 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  top: -10px;
  background-color: white;
  /* z-index:10; */
}

.bordered-ca-table th:first-child {
  white-space: nowrap;
  position: sticky;
  top: -10px;
  left: 0;
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.3),
    inset 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
  z-index: 10;
}
.ca-table {
  padding: 0;
  margin: 0;
}
.ca-table input {
  margin: 0;
  border: 0;
}
.ca-table th {
  background-color: #f6f8fa;
}
.ca-table th,
.ca-table td {
  border: thin ridge;
}
.selectft {
  padding: 0;
  margin: 0;
  width: 240px;
  border: 0;
}
.ca-table-div {
  border-bottom: thin ridge;
}
.comp-analysis-legend ul {
  display: flex;
}
.comp-analysis-legend ul li {
  padding-right: 10px;
}
.comp-analysis-legend ul li i {
  color: #ffd700;
}