.strategyplansummary table tbody,
.strategyplansummary table tbody td,
.strategyplansummary th {
  border: 1px solid black;
  text-align: left;
  padding-left: 10px;
}
.gantt-chart-container {
  width: 80% !important;
  position: absolute;
}
.gantt-chart-button button {
  background-color: #f3f3f3;
  border: none;
  padding: 5px 10px;
  margin: 10px 0px;
  font-size: var(--sub-heading-font-size);
}
.gantt-chart-button button:nth-child(4) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.gantt-chart-button button:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.gantt-chart-button .active {
  background-color: #dadada;
}

.kpirhpobutton {
  cursor: pointer;
  font-size: var(--sub-heading-font-size) !important;
  background-color: var(--button-primary-color) !important;
  padding: 4px 10px !important;
  border: 1px solid var(--button-primary-color) !important;
  position: absolute !important;
  top:82px !important;
  right: 225px !important;
  z-index: 1;
}

.side_bar_heading {
  font-size: var(--sub-heading-font-size);
  display: flex;
  margin-left: 10px;
  font-weight: var(--heading-font-weight);
  text-wrap: nowrap;
}
.gtm-sidebar-body .row .inputField {
  display: flex;
  align-items: center;
  /* margin: auto; */
  margin-left: 3px;
  width: 49%;
  margin-bottom: 20px;
}