.search-container {
    position: relative;
}

.search-input {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: gray;
    cursor: pointer;
}

.search-results {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    position: absolute;
    background: white;
    border: 1px solid lightgrey;
    border-radius: 5px
}

.search-result-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.search-result-item:hover {
    background-color: #ecf4f3;
}

.closeIconWrapper {
    background-color: white;
    width: fit-content;
    height: 35px;
    margin-bottom: 2px;
    margin-left: 5px;
    padding: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    cursor: pointer;
}

.closeIconWrapper:hover {
    background-color: red;
    /* Change this to the desired background color on hover */
}
