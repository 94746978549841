
.metrics-cardlist{
    display:flex;
    height: 40%;
    overflow: auto;
    position: absolute;
    width: 60%;
  }
.metrics-icon{
    color: #8746F1;
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 64px;
}
  /* Card */
  .metrics-card {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    /* background: ; */
    margin: 20px 10px;
    cursor: pointer;
    width: 220px;
    height:200px;
    border: thin ridge ;
    border-radius:10px;
    box-shadow: 0px 0px 8px rgba(41, 59, 48, 0.08);
    
  }
  .metrics-card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  /* Selectable */
  .metrics-card .selectable {
    position: relative;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border: 2px solid transparent;
    border-radius: 10px;
    overflow: hidden;
    width: 220px;
    height:200px;
  }
  .metrics-card .selectable .check {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 20px;
    margin-top: 10px;    

  }
  .metrics-card .selectable .check:before {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    content: "";
    border-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .metrics-card .selectable .check .checkmark {
    display: none;
    font: 20px;
    line-height: 20px;
    text-align: center;
    color: transparent;
  }
  .metrics-card .selectable.selected .check .checkmark {
    display: block;
    font: 20px;
    line-height: 20px;
    text-align: center;
    color: transparent;
  }
  .metrics-card .selectable.selected {
    border-color: #6DC890;
    border-width: thin;
    width: 220px;
    height:200px;
    background-color: #F2FDF6;
  }
  .metrics-card .selectable.selected .check:before {
    border-color: #44aadd #44aadd rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
  }
  .metrics-card .selectable.selected .check .checkmark {
    color: #fff;
  }
  
  .metrics-content {
    padding: 14px;
    margin-top: 10px;

  }
  .metrics-content .title,
  .metrics-content .description {
    margin: 0;
    padding: 4px;
  }
  
  .metrics-column {
    float: left;
    width: 50%;
  }
  .metrics-title {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 600;

  }

  .metrics-description {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    margin-top: 10px;
  }

  .metrics-recommended{
    position: absolute;
    bottom: 0px;
    height: 25px;
    background: #D9F6E4;
    width: 100%;
    text-align: center;
    font-size: 12px;
    justify-content: center;
    display: flex;
    padding-top: 3px;
    gap: 5px;
  }