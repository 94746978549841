.exe-readiness-card-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 85%;
}
.exe-readiness-card {
  display: flex;
  width: 230px;
  padding: 12px 12px 16px 12px;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  height: 220px;
  margin-left: 0px !important;
  margin-right: 30px !important;
}
.exe-readiness-card-title {
  font-size: var(--sub-heading-font-size)
}
.exe-readiness-progress-card {
  height: 3px;
  width: 100%;
  background-color: #eaeaea;
}
.exe-readiness-inner-progress {
  width: 6px;
  height: 3px;
  background-color: #0da14b;
}
.exe-readiness-children {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.exe-readiness-children-child {
  padding: 4px 12px;
  align-items: center;
  border-radius: 12px;
  background-color: #d9f6e4;
  color: #0a5f59;
  font-size: var(--captions-font-size);
}
.exe-readiness-cardmodal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f6f8fa;
  cursor: pointer;
  width: 230px;
  height: 220px;
  border: 1px dashed;
  border-radius: 10px;
  text-align: center;
}

.ERF-Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 4px 0px #32302c1a;
  width: Fill (1, 283px);
  height: Hug (40px);
  padding: 6px 8px 6px 8px;
  border: 0px 0px 1px 0px;
  margin-right: 10px;
}

.exe-readiness-card-selected {
  background-color: #f2fdf6;
  border: 1px solid green;
  border-width: thin;
}

.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 70%;
  margin: 0 auto;
  font-size: 2.2em;
  font-weight: 100;
  color: #555;
}
.indicator-number{
color:  #202020;
text-align: center;

font-size: 24px;
font-style: normal;
font-weight: 700;
}

.indicator-text{
  color:  #646464;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px;
}