button:disabled{
    background-color: #7F9E9C;
}
.add-dimension{
    background-color: #0A5F59;
}
.selectvc{
    width: 200px;
}
li{
    cursor: pointer;
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.addedvc-menu{
    margin-bottom:10px;
    margin-right: 10px;
    /* padding: 10px;
    padding-left: 15px;
    padding-right: 15px; */
    padding: 4px 10px !important;
    border-radius: 10px;
    background-color: white;
    border: thin ridge;
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.addedvc-menu.active{
    background-color: #0A5F59;
    padding: 4px 10px !important;
    border-radius: 10px;
    color:white;
}

/* .addvc-input{
    height: 30px;
    width: 20%;
} */
.addvc{
    margin-left: 10px;
    background: white;
    color: black;
    border-radius: 10px;
    border: thin ridge;
    height: 40px;
    font-size: var(--text-font-size);
    margin-top: 0.5rem;
    padding: 0px 10px;
    display:flex;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
}
.add-icon{
    margin-right: 10px;
    background: white;
    border-color: white;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    border: thin ridge;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
}
.popover{
    max-width: 50%;
    overflow: auto;
    margin-top: 5px;
    margin-left:20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.vccheckbox{
    accent-color: #0DA14B;
}
.selectpr{
    min-width:200px;
    /* position:absolute !important; */
}

.vcselection-table{
    height: 60%;
    /* position: absolute; */
    /* overflow: auto; */
    width:fit-content;
}

  td.products{
    text-align:-webkit-left;
  }

  .bordered-table {
    border-collapse: collapse;
    margin: 5px;
  }
  .bordered-table th, .bordered-table td {
    padding-top: 8px;
    /* padding-left: 8px; */
    vertical-align: middle;
    background: white;
  }
  .bordered-table th {
    text-align:-webkit-center;
  }
  .bordered-table tbody tr td:first-child{
    position:sticky;
    left:0;
    background-color: white;
  }
  
  .bordered-table thead tr.remove-row{
    position:sticky;
    right:0;
    background-color: white;
    width: 40px;
    z-index: 10;
    

  }
  .bordered-table thead tr.id-row{
    position:sticky;
    left:0;
    background-color: white;
    z-index: 10;
  }
  .bordered-table thead {
    position: sticky;
    top:0;
    background-color: white;
    z-index: 10;
  }



