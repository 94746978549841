:root {
  /* Global colors */
  --button-primary-color:#0a5f59;
  --border-color: #eaeaea;
  --main-menu-bg-color: #d9e6e4;
  --card-desc-color: #d9f6e4;
  --card-text-color: #0a5f59;
  --sub-menu-bg-color: #ecf4f3;
  --text-color: black;
  --text-sec-color: white;
  --required-color: red;
  --active-color: green;
  --inactive-color: red;
  --background-color: white;  /* backgournd white color */
  --eco-report-sub-heading-background-color: rgb(217 230 228);
  --eco-report-sub-child-color: #f09642;
  --eco-report-main-heading-color: #07403c;
  --eco-report-child-color: #0a5f59;



  /* Global Font Sizes*/
  --menu-heading-font-size: 14px;
  --menu-font-size: 14px;
  --main-heading: 24px;
  --sub-heading-font-size: 16px;
  --heading-font-weight: bold;
  --text-font-size: 16px;
  --captions-font-size: 12px;
  --task-title-font-size: 24px;
  --maintenace-font-size: 42px;
}
