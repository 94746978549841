
.topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }

  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    background-color: #04AA6D;
    color: white;
  }

  .databars {
    /* width:80px; */
    height:30px;
    /* margin-top: 10%; */
    background-color: #ddd;
    border-radius: 5px;
  }

  .databars.active{
    background-color: #0DA14B;
  }
.menus{
  background: white;
  border-color: white;
  border-style: unset;
  margin-right: 10px;
}
  .menus.active{
    border-bottom: 2px solid #0A5F59;
  }
  .modalmenus{
    margin:10px;
    padding-left: 10px;
    cursor: pointer;
  }
  .modalmenus.active{
    background-color: white;
    border-radius: 5px;
  }
  .menuedit{
    margin-left:250px;
    background: white;
    border: 1px ridge;
    border-radius: 5px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #fff !important;
    padding: 20px;
    border-radius: 4px;
    max-width: 650px;
    width: 100%;
    border-radius: 5px !important;
    position: relative;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    margin-left: 10px;
  }

  td:nth-child(n+2){
    text-align:-webkit-center;
  }
  td.products{
    text-align:-webkit-left;
  }
  
  .mapping-table{
      height: 100%;
      /* position: absolute; */
      /* overflow: auto; */
      width:fit-content;
  }
