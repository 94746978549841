/* CSS for CommentSectionContainer */
.comment-section-container {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  font-family: "Source Sans Pro" !important;
  background-color: #f8fafb;
}

/* CSS for CommentList */
.comment-list {
  list-style: none;
  padding: 0;
  margin-top: 60px;
  font-family: "Source Sans Pro" !important;
  /* padding-bottom: 180px; */
}

/* input {
  width: 80%;
} */
/* CSS for CommentAuthor */
.comment-author {
  font-weight: bold;
  margin-right: 5px;
}

/* CSS for CommentText */
.comment-text {
  margin-left: 20px;
}

/* CSS for CommentActions */
.comment-actions {
  /* margin-top: 10px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* CSS for CommentEditTextarea */
.comment-edit-textarea {
  width: 100%;
  /* padding: 5px; */
  margin-bottom: 5px;
}

/* CSS for CommentIcons */
.comment-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-textarea {
  margin-top: 10px;
  margin-left: 10px;
  width: 98%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* CSS for SubmitButton */
.submit-buttons {
  position: absolute;
  color: #fff;
  /* border: none; */
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
}
.comment {
  display: flex;
  justify-content: space-between;
}
.comment-icons {
  position: relative;
}
.menu-options {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 100;
}
.Edit {
  width: 100px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.EditOption,
.DeleteOption {
  width: 100%;
  padding: 5px;
  font-size: var(--sub-heading-font-size),
}
.EditOption:hover,
.DeleteOption:hover {
  background-color: #eeeeee;
}

.savedata,
.canceldata {
  position: absolute;
  cursor: pointer;
  top: 98px;
  right: 20px;
}
.canceldata {
  right: 160px;
}