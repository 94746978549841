.buttonStyle {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  color: #434343;
  font-size: var(--sub-heading-font-size);
}
.ObjectInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  border: 0 0 1px 0;
  box-shadow: 0 2px 4px 0 #4e4c421a;
  padding: 6px 8px;
}
.ObjectInput button {
  background-color: transparent;
  border: none;
}
.Objective {
  height: auto;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #32302c1a;
  border-left: 5px solid #51a5f2;
  margin-bottom: 10px;
  color: #434343;
}
.goal {
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px #32302c1a;
  height: auto;
  margin-bottom: 10px;
  border-left: 5px solid #8746f1;
  margin-right: 10px;
}

.biname {
  width: 100%;
  border-radius: 8px;
  padding: 10px 0px;
  box-shadow: 0px 1px 4px 0px #32302c1a;
  height: 50px;
  margin-bottom: 20px;
  border-left: 5px solid #8746f1;
}
.ObjectiveData,
.goalData {
  padding-left: 40px;
  padding-top: 10px;
  color: #434343;
}

.color1 {
  background-color: #8746f1;
}
.color2 {
  background-color: #51a5f2;
}
.color3 {
  background-color: #df58c9;
}

/* Apply the colors in a repeating pattern for an unlimited number of buttons */
.button-container button:nth-child(3n + 1) {
  background-color: #8746f1;
} /* Every 1st button */
.button-container button:nth-child(3n + 2) {
  background-color: #51a5f2;
} /* Every 2nd button */
.button-container button:nth-child(3n) {
  background-color: #df58c9;
} /* Every 3rd button */

.simpContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;
  box-shadow: 0px 1px 4px 0px #32302c1a;
  width: Fill (1, 283px);
  height: Hug (40px);
  padding: 6px 8px 6px 8px;
  border: 0px 0px 1px 0px;
  margin-right: 10px;
}

.Milestones {
  background: linear-gradient(0deg, #eeeeee, #eeeeee),
    linear-gradient(0deg, #f8f8f8, #f8f8f8);
  padding: 5px 20px;
  margin-right: 10px;
}
.EditIcon {
  padding: 3px 5px;
}
.ProfileAddIcon,
.EditIcon,
.DeleteIcon,
.ElipsisVerticalIcon {
  padding: 3px 5px;
  border-radius: 30px;
  margin-left: 16px;
  cursor: pointer;
}

.Addobjstyle {
  color: #434343;
  background-color: transparent;
  border: none;
}
.ElipsisVerticalIcon {
  position: relative;
  cursor: pointer;
}
.IconsContainer {
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 100;
  font-size: var(--sub-heading-font-size);
}
.IconsContainers {
  display: none;
  position: absolute;
  top: 20px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 100;
}

.ElipsisVerticalIcon:hover .IconsContainer,
.ElipsisVerticalIcon:hover .IconsContainers {
  display: block;
}
.ElipsisDeleteIcon,
.ElipsisEditIcon {
  padding: 10px;
  display: flex;
}
.ElipsisDeleteIcon:hover,
.ElipsisEditIcon:hover {
  background-color: #eeeeee;
}

.gtm-sidebar-header {
  height: 50px;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  padding: 10px;
}

/* Style the sidebar body */
.gtm-sidebar-body {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

/* Style the sidebar footer */
.gtm-sidebar-footer {
  height: 150px;
  background-color: #f0f0f0;
  padding: 10px;
  border-top: 1px solid lightgrey;
}

.strategy-sidebar-footer {
  height: 100px;
}

.goal-selector {
  width: 100%;
}

.custom-goal-select__control {
  border-radius: 10px;
}

.custom-goal-select__control--is-disabled {
  background-color: white !important;
  border-radius: 10px !important;
  font-size: 20px;
}

.custom-goal-select__input-container {
  margin: 0px !important;
  width: 0px;
}

.select-collaborator {
  width: 84%;
}

.xl-ex-guide-text {
  margin-top: 20px;
  padding-left: 10px;
}
.xl-ex-guide-text label {
  font-size: var(--text-font-size);
  font-weight: var(--heading-font-weight);
}
