.secondsidebar-item.open > .sidebar-content {
  height: auto;
}
.secondsidebar-item.open > .secondsidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}

.secondsidebar-item.open > .sidebar-content {
  height: auto;
}

.secondsidebar-item.plain {
  color: #fff;
  text-decoration: none;
}
.secondsidebar-item.plain:hover {
  text-decoration: underline;
}
.secondsidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}
.secondsidebar-item {
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
}
/* .secondsidebar-item.active{
    font-weight:700;
  }  */
.link_text.active,
.link_text.active-item {
  font-weight: 700;
}