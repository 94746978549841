.Login {
  float: center;
  background-color: grey;
}

.container1 {
  padding: 5%;
}

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  border: 1px solid #888;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  width: 40%; /* Could be more or less, depending on screen size */
}

input[type="text"],
input[type="password"] {
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* button {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  button:hover {
    opacity: 0.8;
  } */

body {
  font-family: "Source Sans Pro" !important;
}

/* Full-width input fields */
input.username input.password {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Set a style for all buttons */
button[type="submit"] {
  background-color: #04aa6d;
  color: white;
  /* padding: 14px 20px; */
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}
.hint-message {
  color: red !important;
  font-size: var(--text-font-size);
  margin-top: 5px;
}
.error-message {
  color: red;
  text-align: left;
  font-size: var(--text-font-size);
  margin-top: 5px;
}

.success-message {
  color: #74808b !important;
  text-align: left;
  font-size: var(--text-font-size);
  margin-top: 5px;
}
.password-toggle-login {
  position: absolute;
  top: 152px;
  right: 20%;
  cursor: pointer;
  z-index: 1;
}
.forgot-password-login {
  text-align: right;
}
.forgot-password-login a {
  color: black;
  text-decoration: none;
}

.forgot-password-login a:hover {
  text-decoration: underline;
  color: black;
}