.select-unit,
.select-currency {
  border: 0;
  padding: 0px !important;
  padding-left: 5px !important;
  padding-top: 5px !important;
}
.market-size-input-container {
  width: 50%;
}
.market-size-input-sub-section {
  width: 98%;
}
.market-size-textarea-container {
  width: 50%;
}
.market-size-input {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 8px;
  height: 36px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #ccc;
}

.market-size-remarks {
  padding-right: 10px;
  margin-top: 8px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #ccc;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.popover-fileupload-m {
  width: 420px;
  z-index: 9999;
  position: absolute;
  transform: translate3d(0px, 70px, 0px);
}
.progress-bar {
  background-color: #0da14b;
}
.marketsize-upload-files {
  width: 50%;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .market-size-input-container {
    width: 100%;
  }
  .market-size-textarea-container {
    width: 100%;
  }
  .marketsize-upload-files {
    width: 100%;
  }
}
