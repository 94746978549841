.cardlist {
  display: flex;
  flex-wrap: wrap;
}

.card {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  background: #fff;
  margin: 5px 10px;
  cursor: pointer;
  width: 160px;
  height: 160px;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/* Selectable */
.card .selectable {
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 2px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  width: 160px;
  height: 160px;
}
.card .selectable .check {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 20px;
}
.card .selectable .check:before {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  content: "";
  border-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.card .selectable.selected {
  border-color: green;
  border-width: thin;
  width: 160px;
  height: 160px;
}
.card .selectable.selected .check:before {
  border-color: #44aadd #44aadd rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
}

.content1 {
  padding: 4px;
}
.content1 .title,
.content1 .description {
  margin: 0;
  padding: 10px;
}

.column {
  float: left;
  width: 50%;
}
.title {
  text-align: left;
  font-size: var(--main-heading);
}

.cardlistmodal {
  display: flex;
}
/* Card */
.cardmodal {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  background: #f6f8fa;
  margin: 5px 10px;
  cursor: pointer;
  width: 160px;
  height: 160px;
  border: 1px dashed;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-dialog {
  margin-top: 10%;
  width: 428px;
}
.product-content {
  padding: 24px;
}
.product-content .title,
.product-content .description {
  margin: 0;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.product-content .description {
  font-size: var(--captions-font-size);
  font-weight: 300;
}

.column {
  float: left;
  width: 50%;
}
.title {
  text-align: left;
  font-size: var(--main-heading);
}
.product-card-title {
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}

.prdicon {
  width: 50px;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
