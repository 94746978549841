.momentum-score {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  text-align: center;
  padding-top: 2px;
  color: black;
  border-radius: 30px;
}

.card-container {
  background-color: #f6f8fa;
  height: 400px;
  padding: 16px;
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: auto;
}
.rows {
  display: flex;
  width: 100%;
  overflow-x: auto;
}
.card-wrapper {
  margin-right: 20px;
  width: 255px;
  padding: 12px;
  border-radius: 8px;
  border: 0px 0px 0px 2px;
  gap: 8px;
}

.cards {
  width: 255px;
  padding: 12px 16px 10px 16px;
  background-color: white;
  box-shadow: 0px 1px 4px 0px #32302c1a;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.card-body {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.card-title {
  width: 150px;
  margin-top: 5px;
  font-size: 16px;
  padding-left: 5px;
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}
.strategic_card {
  cursor: pointer;
  padding: 12px, 16px, 16px, 16px;
  width: 255px;
  background-color: white;
  box-shadow: 0px 1px 4px 0px #32302c1a;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 10px;
  gap: 10px;
}

.sub_strategic_card {
  display: flex;
  justify-content: space-between;
}

.stratgic_card_title {
  font-weight: 400;
  padding: 10px 15px;
  font-size: var(--sub-heading-font-size);
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  align-items: center;
}
.pagination button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.pagination button:hover {
  transform: scale(1.3); /* Slight scale effect on hover */
}
.pagination button:disabled {
  cursor: not-allowed;
  transform: none;
}

.default-progress {
  background-color: #8746f1;
}
.selected-goal {
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #eaeaea;
  height: 30px;
}
