.bicardlist {
  display: -webkit-box;
  overflow-x: auto;
  overflow-y: hidden;
}
.bicardlist > div:first-child {
  margin-left: 0;
}
.bicardlist > div:not(:first-child) {
  margin-left: 20px;
}
/* Card */
.bicard {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  background: #fff;
  cursor: pointer;
  width: 240px;
  height: auto;
  border: thin ridge;
  border-radius: 10px;
  border-color: var(--border-color);
}
.bibottomline {
  border-top: thin ridge;
  height: 40px;
  border-color: var(--border-color);
}

.bidescription {
  float: left;
  padding-top: 5px;
  padding-left: 5px;
  font-size: var(--sub-heading-font-size);
}
.biicon {
  float: left;
  padding-left: 5px;
  padding-top: 35px;
}
.bititle {
  font-size: 15px;
  padding-left: 10px;
}
.status {
  float: right;
}
.status_desc {
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--card-desc-color);
  border-radius: 8px;
  font-size: var(--captions-font-size);
  color: var(--card-text-color);
  padding: 4px 12px;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.bi_title {
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  font-size: var(--sub-heading-font-size);
}
