.comment-section {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin: 10px;
  /* background-color: #f6f8fa81; */
  background-color: #f6f8fab5;
  max-width: 100%;
  margin-top: 50px;
}

.comment-section-heading {
  margin-top: 0;
  color: #333;
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}

.comment-form {
  margin-bottom: 20px;
}

.comment-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: var(--text-font-size);
}

.comment-form .comment-submit-button {
  width: fit-content;
  cursor: pointer;
  margin-left: 10px;
  font-size: var(--sub-heading-font-size);
  background-color: var(--button-primary-color) !important;
  padding: 4px 10px !important;
  border: 1px solid var(--rs-border-primary) !important;
  cursor: pointer;
  border-radius: 5px;
}

.comment-item {
  margin-left: 10px;
  margin-bottom: 10px;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: var(--text-font-size);
}
.commentbox-buttons {
  justify-content: right;
}
