.strategyplan-form-container-layout {
  width: 99%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-layout-heading {
  width: 2%;
  transform: rotate(-90deg);
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-layout1,
.container-layout2,
.container-layout3 {
  display: flex;
  width: 98%;
  background-color: #51a4f238;
  border-radius: 15px;
  padding: 5px;
}
.container-layout2 {
  background-color: #def0ee;
}
.container-layout3 {
  background-color: #8e51892a;
}
.container-layout3 .sub-container-layout {
  width: 50%;
}
.sub-container-layout {
  width: 43%;
  margin: 5px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--text-font-size);
}
.container-layout1 .box-number-input,
.container-layout2 .box-number-input {
  width: 12%;
  margin: 0px 5px;
}
.container-layout1 .box-number-input textarea {
  height: 155px;
}
.container-layout2 .box-number-input textarea {
  height: 540px;
}
.box-number-input label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-size: var(--text-font-size);
}
