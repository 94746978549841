
.cardlist{
    display:flex;
  }
  /* Card */
  .card {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    background: #fff;
    margin: 20px 10px;
    cursor: pointer;
    width: 200px;
    height:200px;
    
  }
  .card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  /* Selectable */
  .card .selectable {
    position: relative;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border: 2px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    width: 200px;
    height:200px;
  }
  .card .selectable .check {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 20px;
  }
  .card .selectable .check:before {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    content: "";
    border-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .card .selectable .check .checkmark {
    display: none;
    font: 20px;
    line-height: 20px;
    text-align: center;
    color: transparent;
  }
  .card .selectable.selected .check .checkmark {
    display: block;
    font: 20px;
    line-height: 20px;
    text-align: center;
    color: transparent;
  }
  .card .selectable.selected {
    border-color: green;
    border-width: thin;
    width: 200px;
    height:200px;
  }
  .card .selectable.selected .check:before {
    border-color: #44aadd #44aadd rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
  }
  .card .selectable.selected .check .checkmark {
    color: #fff;
  }
  
  .content {
    padding: 14px;
  }
  .content .title,
  .content .description {
    margin: 0;
    padding: 4px;
  }
  
  .column {
    float: left;
    width: 50%;
  }
  .title {
    text-align: left;
    font-size: var(--main-heading);
  }